<template>
  <v-sheet class="elevation-3 pa-5">

    <options-form></options-form>
    <options-list></options-list>

  </v-sheet>

</template>

<script>

import List from "@/components/venchi-options/List";
import Form from "@/components/venchi-options/Form";

export default {
  components: {
    'options-list': List,
    'options-form': Form
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
</style>
