<template>
    <v-dialog
        v-model="dialog"
        max-width="700px"
        persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            class="mb-2"
            v-bind="attrs"
            v-on="on"
            :disabled="isLoading"
        >
          {{$t('commons.actions.new')}}
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          {{ formTitle }}
        </v-card-title>

        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-container>

            <v-row>
              <v-col cols="7">
                <v-text-field
                    v-model="option.name"
                    :rules="requiredRules"
                    :label="$t('options.fields.name')"
                    required
                    outlined
                    dense
                    :disabled="isEditing"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="7">
                <v-text-field
                    v-model="option.description"
                    :rules="requiredRules"
                    :label="$t('options.fields.description')"
                    required
                    outlined
                    dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="7">
                <v-text-field
                    v-model="option.value"
                    :rules="requiredRules"
                    :label="$t('options.fields.value')"
                    required
                    outlined
                    dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row>
              <v-col>
                <dialog-buttons
                    :valid="valid"
                    :is-loading="isLoading"
                    @save="storeUser"
                    @close="close"
                ></dialog-buttons>
              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card>

    </v-dialog>
</template>

<script>

  import clonedeep from 'lodash.clonedeep'
  import DialogButtons from "@/components/form/DialogButtons";
  import {mapGetters} from "vuex";
  import {EventBus} from "@/event-bus";
  import { validationRules } from "@/mixins/validationRules";

  export default {
    name: "Form",

    components: {DialogButtons},

    mixins: [validationRules],

    data: () => ({
      option: {},
      defaultOptions: {
        name: '',
        description: '',
        value: ''
      },
      isEditing: false,
      dialog: false,
      sending: false,
      valid: false,
    }),

    mounted() {

      let self = this

      this.option = clonedeep(this.defaultOptions)

      EventBus.$on('edit-option', function (option) {
              self.option = clonedeep(option)
        self.isEditing = true
        self.dialog = true
      })
    },

    computed: {
      ...mapGetters({
        isLoading: 'venchiOption/loadingState'
      }),
      formTitle () {
        return (this.isEditing === true) ? this.$t('options.edit.title') : this.$t('options.create.title')
      },
    },

    methods: {
      async storeUser() {
        const valid = await this.$refs.form.validate()

        if (valid) {
          if (!this.isEditing) {
            await this.$store.dispatch('venchiOption/store', this.option)
                .then(resp => {
                  console.log('then close dialog resp ', resp)
                  this.close()
                })
                .catch(err => {
                  console.log('create error err ', err)
                })
          } else {
            await this.$store.dispatch('venchiOption/update', this.option)
                .then(resp => {
                  console.log('then close dialog resp ', resp)
                  this.close()
                })
                .catch(err => {
                  console.log('create error err ', err)
                })
          }
        }
      },

      close () {
        this.dialog = false
        this.valid = true
        this.$refs.form.reset()
        this.$nextTick(() => {
          this.isEditing = false
          this.option = clonedeep(this.defaultOptions)
        })
      },
    }
  }
</script>

<style scoped>
.bordered {
  border: 1px solid #dddddd;
}

</style>