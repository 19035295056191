<template>
  <div>

    <v-divider class="my-3"></v-divider>

    <commons-filter :loading="isLoading"
                    :freeText.sync="filters.name"
                    :free-search-filter="true"
                    @change="applyFilter"
    />

    <v-data-table
        :loading="loading"
        :loading-text="$t('commons.loading')"
        :headers="headers"
        :items="options"
        :items-per-page="pagination.per_page"
        :hide-default-footer="true"
        class="elevation-0 clickable"
        disable-pagination
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="edit(item)"
        >
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>

    <v-divider></v-divider>

    <div class="text-center py-3">
      <v-pagination
          v-model="pagination.current"
          :length="pagination.total"
          :total-visible="5"
          @input="getOptions()"
          :disabled="isLoading"
      ></v-pagination>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex"
import {EventBus} from "@/event-bus"
import CommonsFilter from "@/components/filters/CommonsFilter";

export default {
  name: "List",

  components: {
    CommonsFilter
  },

  data() {
    return {
      userFilter: '',
      filterMenu: false,
      filters: {},
      headers: [
        {text: this.$t('options.fields.name'), value: 'name', sortable: false, width: '15%'},
        {text: this.$t('options.fields.description'), value: 'description', sortable: false, width: '30%'},
        {text: this.$t('options.fields.value'), value: 'value', sortable: false},
        {text: '', value: 'actions', sortable: false, width: '4%', align: 'center'}
      ]
    }
  },

  mounted() {
    this.getOptions()
  },

  methods: {
    applyFilter() {
      this.getOptions()
    },
    getOptions() {
      this.$store.dispatch('venchiOption/fetchVenchiOptions', this.filters)
    },
    edit(option) {
      console.log('edit option ', option)
      this.isEditing = true
      EventBus.$emit('edit-option', option)
      this.dialog = true
    },

  },

  computed: {
    ...mapGetters({
      isLoading: 'venchiOption/loadingState',
      options: 'venchiOption/getVenchiOptions',
      pagination: 'venchiOption/getPagination'
    }),

    loading: {
      get() {
        return this.isLoading
      },
      set(v) {
        this.$store.dispatch('venchiOption/setLoadingStatus', v)
      }
    },
  }
}
</script>

<style scoped>

</style>
